
import React from 'react';
//import { FaGithub, FaLinkedin, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer id="contact">
     
        <p>© 2024 Romell Mertis. All rights reserved.</p>
      </footer>
    
  );
};

export default Footer;
